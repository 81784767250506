import request from "@/api/request";
// const basUrl = "http://cpsapi.yy12365.com";
// const localUrl = "http://192.168.0.113";

// 查询发票列表（无分页）
function getinvoiceList(id, data) {
  return request({
    method: "get",
    url: `/increaseticketqualification/listAll?operatorId=${id}`,
    data,
  });
}
// 发票分页
function getinvoiceListAll(pageNumber, pageSize, invoice, extendColumn4, data) {
  return request({
    method: "get",
    url: `/increaseticketqualification?pageNumber=${pageNumber}&pageSize=${pageSize}&extendColumn4=${extendColumn4}&increaseTicketType=${invoice}`,
    data,
  });
}
// 新增发票
function getTotalAmountSigned(pageNumber, pageSize, name, data) {
  return request({
    method: "get",
    url: `/requestaninvoice/getTotalAmountSigned?pageNumber=${pageNumber}&pageSize=${pageSize}&name=${name}`,
    data,
  });
}
// 开票记录列表
function requestaninvoiceInfoList(
  pageNumber,
  pageSize,
  startTime,
  endTime,
  invoicingApplicant,
  invoicingUnit,
  data
) {
  return request({
    method: "get",
    url: `/requestaninvoice?pageNumber=${pageNumber}&pageSize=${pageSize}&startTime=${startTime}&endTime=${endTime}&customerServiceUserId=${invoicingApplicant}&customerUserId=${invoicingUnit}`,
    data,
  });
}
// 开票
function doInvoicing(data) {
  return request({
    method: "post",
    url: "/requestaninvoice",
    data,
  });
}
// 查询发票详情
function queryinvoice(id, data) {
  return request({
    method: "get",
    url: `/increaseticketqualification/${id}`,
    data,
  });
}

// 查询是否存在增值税发票
function queryInfo(data) {
  return request({
    method: "get",
    url: `/increaseticketqualification/check`,
    data,
  });
}
// 新增发票
function addinvoice(data) {
  return request({
    method: "post",
    url: "/increaseticketqualification",
    data,
  });
}

// 修改发票
function editinvoice(id, data) {
  return request({
    method: "put",
    url: `/increaseticketqualification/${id}`,
    data,
  });
}
// 删除发票
function delinvoice(id, data) {
  return request({
    method: "delete",
    url: `/increaseticketqualification/${id}`,
    data,
  });
}
// 充值接口
function recharge(data) {
  return request({
    method: "post",
    url: `/usewallet/recharge`,
    data,
  });
}

// 支付密码设置接口
function setPass(data) {
  return request({
    method: "put",
    url: `/usewallet/addUserWalletPassword`,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

// 查询支付状态
function queryPassType(data) {
  return request({
    method: "post",
    url: `/pay/getPayStruts`,
    data,
  });
}
// 查询用户列表
function queryUser(pageNumber, pageSize, data) {
  return request({
    method: "get",
    url: `/user?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    data,
  });
}
// 钱包明细
function walletInfo(pageNumber, pageSize, type, extendColumn2, data) {
  return request({
    method: "get",
    url: `/usewallet?pageNumber=${pageNumber}&pageSize=${pageSize}&type=${type}&extendColumn2=${extendColumn2}`,
    data,
  });
}
// 消费中心
function consumerCenter(startTime, endTime, pageSize, pageNumber, type, userId, data) {
  return request({
    method: "get",
    url: `/usewallet/consumerCenter?startTime=${startTime}&endTime=${endTime}&pageSize=${pageSize}&pageNumber=${pageNumber}&type=${type}&userId=${userId}`,
    data,
  });
}

// 获取未结款订单
function queryUnpaidOrder(
  startTime,
  endTime,
  pageSize,
  pageNumber,
  whetherFullPayment,
  data
) {
  return request({
    method: "get",
    url: `/orderinfo/orderInfoList?startTime=${startTime}&endTime=${endTime}&pageSize=${pageSize}&pageNumber=${pageNumber}&whetherFullPayment=${whetherFullPayment}`,
    data,
  });
}

// 校验支付密码
function checkPayPassWord(data) {
  return request({
    method: "put",
    url: `/usewallet/checkPayPassWord`,
    data,
  });
}
// 获取订单列表
function queryOrder(
  startTime,
  endTime,
  customerid,
  pageSize,
  pageNumber,
  userid,
  payStruts,
  orderId,
  whetherFullPayment,
  data
) {
  return request({
    method: "get",
    url: `/orderinfo/orderInfoList?startTime=${startTime}&endTime=${endTime}&customerid=${userid}&pageSize=${pageSize}&pageNumber=${pageNumber}&userid=${customerid}&payStruts=${payStruts}&whetherFullPayment=${whetherFullPayment}&id=${orderId}`,
    data,
  });
}

function queryInvoiceOrder(
    startTime,
    endTime,
    customerid,
    pageSize,
    pageNumber,
    userid,
    payStruts,
    orderId,
    whetherFullPayment,
    data
) {
  return request({
    method: "get",
    url: `/orderinfo/getInvoiceOrderInfoList?startTime=${startTime}&endTime=${endTime}&customerid=${userid}&pageSize=${pageSize}&pageNumber=${pageNumber}&userid=${customerid}&payStruts=${payStruts}&whetherFullPayment=${whetherFullPayment}&id=${orderId}`,
    data,
  });
}

// 订单支付接口
function orderpayinfo(data) {
  return request({
    method: "post",
    url: `/orderpayinfo`,
    data,
  });
}
// 查询信用和余额
function getUserMoneyAndCredits(type, id, data) {
  return request({
    method: "get",
    url: `/user/getUserMoneyAndCredits?type=${type}&id=${id}`,
    data,
  });
}
// 申请开票
function applyKai(data) {
  return request({
    method: "post",
    url: `/issueaninvoice`,
    data,
  });
}
// 开票记录表

function invoiceList(pageSize, pageNumber, orderId, issueStruts, data) {
  return request({
    method: "get",
    url: `/issueaninvoice?pageSize=${pageSize}&pageNumber=${pageNumber}&orderId=${orderId}&issueStruts=${issueStruts}`,
    data,
  });
}
// 消费记录当前订单详情
function reciordInfo(type, ordeRnumber, extendColumn2, data) {
  return request({
    method: "get",
    url: `/usewallet/listAll?type=${type}&ordeRnumber=${ordeRnumber}&extendColumn2=${extendColumn2}`,
    data,
  });
}
// 订单流程图
function oplogger(id, data) {
  return request({
    method: "get",
    url: `/oplogger/listAll?orderId=${id}`,
    data,
  });
}
//询价记录
function inquirycecord(pageSize, pageNumber, startTime, endTime, data) {
  return request({
    method: "get",
    url: `/inquirycecord?pageSize=${pageSize}&pageNumber=${pageNumber}&startTime=${startTime}&endTime=${endTime}`,
    data,
  });
}
// 测试公司信息
function companyinformation(data) {
  return request({
    method: "get",
    url: `/companyinformation/100000`,
    data,
  });
}
// 应收对账列表
function receivable(
  customerId, // 客户id
  salesmanid, // 客服id
  startTime,
  endTime,
  pageSize,
  pageNumber,
  data
) {
  return request({
    method: "get",
    url: `/reconciliation/receivable?customerId=${customerId}&salesmanid=${salesmanid}&startTime=${startTime}&endTime=${endTime}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
    data,
  });
}
// 客户客服联动查询
// 客户id ，客服id
function getAllCustomerAndService(clientId, customerId, data) {
  return request({
    method: "get",
    url: `/reconciliation/getAllCustomerAndService?clientId=${clientId}&customerId=${customerId}`,
    data,
  });
}
// 应收导出
function exportReceivable(
  customerId, // 客户id
  salesmanid, // 客服id
  startTime,
  endTime,
  pageSize,
  pageNumber,
  data
) {
  return request({
    method: "get",
    url: `/reconciliation/exportReceivable??customerId=${customerId}&salesmanid=${salesmanid}&startTime=${startTime}&endTime=${endTime}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
    data,
  });
}
// 签收明细-本期新增
// id ，开始时间 ， 结束时间
function showDetailsQs(customerId, startTime, endTime) {
  return request({
    method: "get",
    url: `/reconciliation/showDetailsQs?customerId=${customerId}&startTime=${startTime}&endTime=${endTime}`,
  });
}
// 支付明细-本期减少
// id ，开始时间 ， 结束时间
function showDetailsPay(customerId, startTime, endTime) {
  return request({
    method: "get",
    url: `/reconciliation/showDetailsPay?customerId=${customerId}&startTime=${startTime}&endTime=${endTime}`,
  });
}
// 删除订单
function deleOrder(id, data) {
  return request({
    method: "delete",
    url: `/orderinfo/deleteAll/${id}`,
    data,
  });
}
// 查询快捷充值金额项
function queryRechargeconfig(data) {
  return request({
    method: "get",
    url: `/user/queryRechargeConfig`,
    data,
  });
}
function getConfig(data) {
  return request({
    method: "get",
    url: `/file/getConfig`,
    data,
  });
}
function getInvoiceConfig(data) {
  return request({
    method: "get",
    url: `/requestaninvoice/obtainTaxRateConfig`,
    data,
  });
}
function editInvoiceStatus(data) {
  return request({
    method: "put",
    url: `/orderinfo/editInvoiceStatus`,
    data,
  });
}

function queryUser1(
    pageNumber,
    pageSize,
    userLevel,
    userName,
    custName,
    userType,
    data
) {
  return request({
    method: "get",
    url: `/user/allCustomerUser?pageNumber=${pageNumber}&pageSize=${pageSize}&userLevel=${userLevel}&USERNAME=${userName}&custName=${custName}&userType=${userType}`,
    data,
  });
}
export default {
  doInvoicing,
  requestaninvoiceInfoList,
  getinvoiceListAll,
  deleOrder,
  exportReceivable,
  getAllCustomerAndService,
  receivable,
  companyinformation,
  inquirycecord,
  oplogger,
  reciordInfo,
  invoiceList,
  checkPayPassWord,
  applyKai,
  getinvoiceList,
  addinvoice,
  editinvoice,
  queryinvoice,
  delinvoice,
  queryInfo,
  recharge,
  setPass,
  queryPassType,
  queryUser,
  walletInfo,
  queryOrder,
  queryUnpaidOrder,
  orderpayinfo,
  getUserMoneyAndCredits,
  consumerCenter,
  showDetailsQs,
  showDetailsPay,
  getTotalAmountSigned,
  queryRechargeconfig,
  getConfig,
  queryInvoiceOrder,
  editInvoiceStatus,
  getInvoiceConfig,
  queryUser1
};
