<template>
  <div>
    <div class="top">
      <div class="hand_line">
        <div class="title">产品名称</div>
        <span>:</span>
        <div class="text">{{ orderInfo.ordername }}</div>
      </div>
      <div class="hand_line">
        <div class="title">订单编号</div>
        <span>:</span>
        <div class="text">{{ orderInfo.id }}</div>
      </div>
      <div class="hand_line">
        <div class="title">联系人</div>
        <span>:</span>
        <div class="text">{{ orderInfo.customername }}</div>
      </div>
      <div class="hand_line">
        <div class="title">联系电话</div>
        <span>:</span>
        <div class="text">{{ orderInfo.contacttel }}</div>
      </div>
      <!--            <div class="hand_line">-->
      <!--                <div class="title">邮箱</div>-->
      <!--                <span>:</span>-->
      <!--                <div class="text"></div>-->
      <!--            </div>-->
      <div class="hand_line">
        <div class="title">下单日期</div>
        <span>:</span>
        <div class="text">{{ orderInfo.ordertime }}</div>
      </div>
      <div class="hand_line">
        <div class="title">交货日期</div>
        <span>:</span>
        <div class="text">{{ orderInfo.completiontime }}</div>
      </div>
    </div>

    <div class="content">
      <!--手拎袋-->
      <div v-if="productId == 1015">
        <table>
          <tr>
            <th>成品尺寸(高*宽*厚)</th>
            <th>印刷数量</th>
            <th>印刷颜色</th>
            <th>印刷纸张</th>
          </tr>
          <tr>
            <td>{{ info.productSpec }}</td>
            <td>{{ info.productNum }}</td>
            <td>{{ info.printColor }}</td>
            <td>{{ info.paperName }}</td>
          </tr>
          <tr class="packType">
            <th>后道工艺</th>
            <td colspan="4">{{ info.aftDesc }}</td>
          </tr>
          <tr class="packType">
            <th>包装方式</th>
            <td colspan="4">{{ info.packageType }}</td>
          </tr>
        </table>
      </div>

      <!--普通画册-->
      <div v-if="productId == 1020">
        <table>
          <tr>
            <th>成品尺寸(宽*高)</th>
            <th>印刷数量</th>
            <th>装订方式</th>
          </tr>
          <tr>
            <td>{{ info.productSpec }}</td>
            <td>{{ info.productNum }}</td>
            <td>{{ info.bindingType }}</td>
          </tr>
        </table>
        <table>
          <tr>
            <th>部件名称</th>
            <th>P数/尺寸</th>
            <th>印刷颜色</th>
            <th>印刷纸张</th>
            <th>后道工艺</th>
          </tr>
          <tr v-for="(item, index) in info.partsList" :key="index">
            <td style="font-weight: bold">{{ item.partsName }}</td>
            <td>{{ item.pNum }}</td>
            <td>{{ item.printColor }}</td>
            <td>{{ item.paperName }}</td>
            <td>{{ item.aftDesc }}</td>
          </tr>
          <tr class="packType">
            <td style="font-weight: bold">包装方式</td>
            <td colspan="4">{{ info.packageType }}</td>
          </tr>
        </table>
      </div>

      <!--单页-->
      <div v-if="productId == 1028">
        <table>
          <tr>
            <th>成品尺寸(宽*高)</th>
            <th>印刷数量</th>
            <th>印刷颜色</th>
            <th>印刷纸张</th>
            <th>后道工艺</th>
          </tr>
          <tr>
            <td>{{ info.productSpec }}</td>
            <td>{{ info.productNum }}</td>
            <td>{{ info.printColor }}</td>
            <td>{{ info.paperName }}</td>
            <td>{{ info.aftDesc }}</td>
          </tr>
          <tr class="packType">
            <th>包装方式</th>
            <td colspan="4">{{ info.packageType }}</td>
          </tr>
        </table>
      </div>

      <!--封套-->
      <div v-if="productId == 1032">
        <table>
          <tr>
            <th>成品尺寸(高*宽)</th>
            <th>印刷数量</th>
            <th>印刷颜色</th>
            <th>印刷纸张</th>
            <th>后道工艺</th>
          </tr>
          <tr>
            <td>{{ info.productSpec }}</td>
            <td>{{ info.productNum }}</td>
            <td>{{ info.printColor }}</td>
            <td>{{ info.paperName }}</td>
            <td>{{ info.aftDesc }}</td>
          </tr>
          <tr class="packType">
            <th>包装方式</th>
            <td colspan="4">{{ info.packageType }}</td>
          </tr>
        </table>
      </div>

      <!--精装书-->
      <div v-if="productId == 1034">
        <table>
          <tr>
            <th>成品尺寸(高*宽)</th>
            <th>印刷数量</th>
            <th>装订方式</th>
          </tr>
          <tr>
            <td>{{ info.productSpec }}</td>
            <td>{{ info.productNum }}</td>
            <td>{{ info.bindingType }}</td>
          </tr>
<!--          <tr class="packType">-->
<!--            <th>包装方式</th>-->
<!--            <td colspan="2">{{ orderProduct.packType }}</td>-->
<!--          </tr>-->
        </table>
        <table>
          <tr>
            <th>部件名称</th>
            <th>部件数量</th>
            <th>印刷颜色</th>
            <th>印刷纸张</th>
            <th>后道工艺</th>
          </tr>
          <tr v-for="(item, index) in info.partsList" :key="index">
            <td style="font-weight: bold">{{ item.partsName }}</td>
            <td>{{ item.pNum }}</td>
            <td>{{ item.printColor }}</td>
            <td>{{ item.paperName }}</td>
            <td>{{ item.aftDesc }}</td>
          </tr>
          <tr class="packType">
            <td style="font-weight: bold">包装方式</td>
            <td colspan="4">{{ info.packageType }}</td>
          </tr>
        </table>
      </div>

      <!--台历-->
      <div v-if="productId == 1035">
        <table>
          <tr>
            <th>成品尺寸(x*y*z示例图)</th>
            <th>印刷数量</th>
            <th>装订方式</th>
          </tr>
          <tr>
            <td>{{ info.productSpec }}</td>
            <td>{{ info.productNum }}</td>
            <td>{{ info.bindingType }}</td>
          </tr>
          <tr class="packType">
            <th>包装方式</th>
            <td colspan="3">{{ info.packageType }}</td>
          </tr>
        </table>
        <table>
          <tr>
            <th>部件名称</th>
            <th>部件数量</th>
            <th>印刷颜色</th>
            <th>印刷纸张</th>
            <th>后道工艺</th>
          </tr>
          <tr v-for="(item, index) in info.partsList" :key="index">
            <td style="font-weight: bold">{{ item.partsName }}</td>
            <td>{{ item.pNum }}</td>
            <td>{{ item.printColor }}</td>
            <td>{{ item.paperName }}</td>
            <td>{{ item.aftDesc }}</td>
          </tr>
          <tr class="packType">
            <td style="font-weight: bold">成品工艺</td>
            <td colspan="4">{{ info.processName }}</td>
          </tr>
          <tr class="packType">
            <td style="font-weight: bold">包装方式</td>
            <td colspan="4">{{ info.packageType }}</td>
          </tr>
        </table>
      </div>

      <!--挂历-->
      <div v-if="productId == 1036">
        <table>
          <tr>
            <th>成品尺寸(高*宽)</th>
            <th>印刷数量</th>
            <th>装订方式</th>
          </tr>
          <tr>
            <td>{{ info.productSpec }}</td>
            <td>{{ info.productNum }}</td>
            <td>{{ info.bindingType }}</td>
          </tr>
        </table>
        <table>
          <tr>
            <th>部件名称</th>
            <th>部件数量</th>
            <th>印刷颜色</th>
            <th>印刷纸张</th>
            <th>后道工艺</th>
          </tr>
          <tr v-for="(item, index) in info.partsList" :key="index">
            <td style="font-weight: bold">{{ item.partsName }}</td>
            <td>{{ item.pNum }}</td>
            <td>{{ item.printColor }}</td>
            <td>{{ item.paperName }}</td>
            <td>{{ item.aftDesc }}</td>
          </tr>
          <tr class="packType">
            <td style="font-weight: bold">包装方式</td>
            <td colspan="4">{{ info.packageType }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api/webAdmin/webAdmin";
export default {
  name: "simpleDetails",
  props: ["orderId"],
  data() {
    return {
      productId: "",
      info: {},
      orderInfo: {},
      custData: {},
    };
  },
  created() {
    console.log("获取订单id", this.orderId);
    this.init(this.orderId);
  },
  mounted() {},
  methods: {
    init(id) {
      api
        .getProductInfoByOrderId(id)
        .then((res) => {
          console.log("订单详情", res);
          if (res.success) {
            // this.$message({
            //     message: "加载成功",
            //     type: "success",
            // });
            this.custData = res.data.custData == null ? {} : res.data.custData;
            //费用列表
            this.moneyInfo = res.data.moneyInfo;
            //订单信息
            this.orderInfo = res.data.orderInfo;
            //表格
            this.info = res.data.orderProduct.info;
            //订单详情
            this.orderProduct = res.data.orderProduct;
            //产品ID
            this.productId = res.data.orderProduct.productId;
            this.info = JSON.parse(res.data.orderProduct.info);
            console.log(JSON.parse(res.data.orderProduct.info));
          } else {
            // this.$message.error('报价页面加载失败');
            console.log(res.data.message);
          }
        })
        .catch((error) => {
          console.error("Error", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  margin-bottom: 20px;
  .hand_line {
    display: inline-flex;
    width: 50%;
    height: 30px;
    line-height: 30px;

    .title,
    span {
      font-weight: bold;
      color: black;
    }

    .title {
      width: 80px;
      text-align: justify;
      text-align-last: justify;
    }

    .text {
      border-bottom: 1px solid #eee;
      margin-left: 20px;
      color: #666;
      width: calc(100% - 80px);
      text-align: left;
      line-height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .money {
        display: inline-block;
        color: #f29900;
      }
    }
  }
}
/*表格*/
table {
  border: 1px solid #ebeef5;
  width: 100%;
  /*tr{*/
  /*    border-bottom: 1px solid #EBEEF5;*/
  /*}*/
  /*tr:last-of-type{*/
  /*    border-bottom: 0;*/
  /*}*/
  tr {
    th {
      color: #909399;
      font-size: 14px;
      font-weight: bold;
      padding: 12px 10px;
      text-align: center;
      border-right: 1px solid #ebeef5;
      border-bottom: 1px solid #ebeef5;
    }

    th:last-of-type {
      border-right: 0;
    }

    td {
      color: #606266;
      font-size: 14px;
      padding: 12px 10px;
      text-align: center;
      border-right: 1px solid #ebeef5;
      border-bottom: 1px solid #ebeef5;
    }

    td:last-of-type {
      border-right: 0;
    }

    .orange {
      color: #eb6d00;
      font-size: 20px;
    }
  }

  tr:last-of-type th {
    border-bottom: 0;
  }

  tr:last-of-type td {
    border-bottom: 0;
  }

  .packType th {
    border-right: 1px solid #ebeef5 !important;
  }

  .packType td {
    border-right: 1px solid #ebeef5 !important;
  }

  .packType td:last-of-type {
    border-right: 0 !important;
  }
}
</style>
