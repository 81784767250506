<template>
  <div>
    <div class="infos">
      <p>
        <span class="label">发票类型:</span
        ><span>{{
          form.increaseTicketType
        }}</span>
      </p>
      <p>
        <span class="label">开票类型:</span
        ><span>{{ form.extendColumn1 == 1 ? "个人" : "单位" }}</span>
      </p>
      <p v-show="form.extendColumn1 === '1'">
        <span class="label">开票抬头:</span
        ><span>{{ form.increaseTicketTitle }}</span>
      </p>
      <div class="more" v-show="form.extendColumn1 === '2'">
        <p>
          <span class="label">单位名称:</span><span>{{ form.unitName }}</span>
        </p>
        <p>
          <span class="label">发票税号:</span
          ><span>{{ form.increaseTicketNumber }}</span>
        </p>
        <p>
          <span class="label">注册地址:</span
          ><span>{{ form.increaseTicketAdress }}</span>
        </p>

        <p>
          <span class="label">注册电话:</span
          ><span>{{ form.increaseTicketPhone }}</span>
        </p>

        <p>
          <span class="label">开户银行:</span
          ><span>{{ form.increaseTicketBank }}</span>
        </p>

        <p>
          <span class="label">银行账号:</span
          ><span>{{ form.increaseTicketBankNumber }}</span>
        </p>
      </div>
      <p>
        <span class="label">收票人姓名:</span
        ><span>{{ form.recipientSName }}</span>
      </p>
      <p>
        <span class="label">收票人电话:</span
        ><span>{{ form.recipientSPhone }}</span>
      </p>
      <p>
        <span class="label">邮寄地址:</span><span>{{ form.address }}</span>
      </p>
      <p>
        <span class="label">详细地址:</span
        ><span>{{ form.recipientSAdress }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import api from "../../api/invoice/invoice";
export default {
  props: ["increaseId"],
  data() {
    return {
      form: {
        increaseTicketNumber: "", // 发票税号
        increaseTicketBankNumber: "", //银行卡号
        unitName: "", // 单位名称
        increaseTicketBank: "", // 开户银行
        increaseTicketPhone: "", //注册电话
        increaseTicketAdress: "", //单位注册地
        recipientSName: "", // 开票人姓名
        recipientSPhone: "", // 开票人手机号
        address: "", // 详细地址
        increaseTicketType: "1", // 发票类型
        //开票抬头
        increaseTicketTitle: "",
        //开票类型
        extendColumn1: "2",
        recipientSAdress: "", //详细地址
        recipientSProvinceCode: "",
        recipientSProvinceZh: "",
        recipientSCityCode: "",
        recipientSCityZh: "",
        recipientSAreaCode: "",
        recipientSAreaZh: "",
        // //开票金额
        // invoicemoney: "",
        // //申请时间
        // date1: "",
      },
    };
  },
  created() {
    console.log("发票id-increaseId", this.increaseId);
    this.getInfo(this.increaseId);
  },
  methods: {
    // 获取详情
    getInfo(id) {
      api.queryinvoice(id).then((res) => {
        console.log("获取当前发票详情", res);
        if (res.success) {
          let data = res.data;
          // let str =
          //   data.recipientSProvinceCode +
          //   "," +
          //   data.recipientSCityCode +
          //   "," +
          //   data.recipientSAreaCode;
          // let arr = str.split(",");
          this.form = {
            increaseTicketNumber: data.increaseTicketNumber, // 发票税号
            increaseTicketBankNumber: data.increaseTicketBankNumber, //银行卡号
            unitName: data.unitName, // 单位名称
            increaseTicketBank: data.increaseTicketBank, // 开户银行
            increaseTicketPhone: data.increaseTicketPhone, //注册电话
            increaseTicketAdress: data.increaseTicketAdress, //单位注册地
            recipientSName: data.recipientSName, // 开票人姓名
            recipientSPhone: data.recipientSPhone, // 开票人手机号
            address: `${data.recipientSProvinceZh}-${data.recipientSCityZh}-${data.recipientSAreaZh}`, // 详细地址
            increaseTicketType: data.increaseTicketType.toString(), // 发票类型
            //开票抬头
            increaseTicketTitle: data.increaseTicketTitle,
            //开票类型
            extendColumn1: data.extendColumn1
              ? data.extendColumn1.toString()
              : "2",
            recipientSAdress: data.recipientSAdress, //详细地址
            recipientSProvinceCode: data.recipientSProvinceCode,
            recipientSProvinceZh: data.recipientSProvinceZh,
            recipientSCityCode: data.recipientSCityCode,
            recipientSCityZh: data.recipientSCityZh,
            recipientSAreaCode: data.recipientSAreaCode,
            recipientSAreaZh: data.recipientSAreaZh,
          };
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.infos {
  padding: 0 20px;
  font-size: 16px;
  p {
    line-height: 30px;
    .label {
      font-weight: 700;
      margin-right: 15px;
    }
  }
}
</style>
