<template>
  <div ref="editor"></div>
</template>

<script>
import E from "wangeditor";
import url from "../../api/picture";
export default {
  props: {
    info: {
      type: String,
    },
    value: {
      type: String,
      default: "",
    },
    meanArray: {
      // 自定义菜单
      type: Array,
      default: null,
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  watch: {
    // info(v) {
    //   if (v) {
    //     this.editor.txt.html(v);
    //   }
    // },
    value: function (value) {
      if (value !== this.editor.txt.html()) {
        this.editor.txt.html(this.value);
      }
    },
    //value为编辑框输入的内容，这里我监听了一下值，当父组件调用得时候，如果给value赋值了，子组件将会显示父组件赋给的值
  },
  data() {
    return {
      // 默认有这么多菜单，meanArray有值以meanArray为准
      defaultMeanus: [
        "head",
        "bold",
        "fontSize",
        "fontName",
        "italic",
        "underline",
        "strikeThrough",
        "indent",
        "lineHeight",
        "foreColor",
        "backColor",
        "link",
        "list",
        "justify",
        "quote",
        "emoticon",
        "image",
        "video",
        "table",
        "code",
        "splitLine",
        "undo",
        "redo",
      ],
      editor: "",
      url: url.richUrl,
    };
  },
  methods: {
    init() {
      const _this = this;
      this.editor = new E(this.$refs.editor);
      // this.editor.config.uploadImgShowBase64 = true; // 使用 base64 保存图片
      this.editor.config.uploadImgServer = this.url; // 服务器地址
      this.editor.config.uploadFileName = "files";
      this.callback();
      this.setMenus(); //设置菜单
      this.editor.config.onchange = (html) => {
        _this.$emit("change", html); // 将内容同步到父组件中
      };
      this.editor.create(); //创建编辑器
    },
    callback() {
      this.editor.config.uploadImgHooks = {
        // 上传图片之前
        before: function (xhr) {
          console.log("上传图片之前", xhr);

          // 可阻止图片上传
          // return {
          //   prevent: true,
          //   msg: "需要提示给用户的错误信息",
          // };
        },
        // 图片上传并返回了结果，图片插入已成功
        success: function (xhr) {
          console.log("success", xhr);
        },
        // 图片上传并返回了结果，但图片插入时出错了
        fail: function (xhr, editor, resData) {
          // console.log("fail", JSON.parse(resData));
        },
        // 上传图片出错，一般为 http 请求的错误
        error: function (xhr, editor, resData) {
          console.log("error", xhr, resData);
        },
        // 上传图片超时
        timeout: function (xhr) {
          console.log("timeout");
        },
        // 图片上传并返回了结果，想要自己把图片插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
        // customInsert: function (insertImgFn, result) {
        //   // result 即服务端返回的接口
        //   console.log("customInsert", result);

        //   // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
        //   insertImgFn(result.data[0]);
        // },
      };
    },
    setMenus() {
      // 设置菜单
      if (this.meanArray) {
        this.editor.config.menus = this.meanArray;
      } else {
        this.editor.config.menus = this.defaultMeanus;
      }
    },
    getHtml() {
      // 得到文本内容
      return this.editor.txt.html();
    },
    setHtml(txt) {
      // 设置富文本里面的值
      this.editor.txt.html(txt);
    },
  },
  mounted() {
    let that = this;
    that.$nextTick(function () {
      that.init();
      if (that.info) {
        this.editor.txt.html(that.info);
      }
    });
  },
};
</script>
<style lang="sass" scoped></style>
