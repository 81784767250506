<template>
  <div>
    <div class="card">
      <div class="card-top">
        <span></span>
        <h3>订单统计</h3>
      </div>
      <div class="query">
        <el-row :gutter="10">
          <el-col :span="14" class="colls">
            <span>按日期查询:</span>
            <el-date-picker
              v-model="startTime"
              type="date"
              placeholder="选择日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
            <div class="kuoz">-</div>
            <el-date-picker
              v-model="endTime"
              type="date"
              placeholder="选择日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="8" class="coll">
            <span class="span">客户名称:</span>
            <el-input
              v-model="oneMember"
              placeholder="请选择客户"
              @focus="changeMember"
            ></el-input>
          </el-col>
        </el-row>
        <el-row class="rows">
          <el-col :span="6">
            <el-select
              placeholder="请选择订单支付方式"
              v-model="whetherFullPayment"
              clearable
            >
              <el-option
                :key="item.value"
                :label="item.label"
                :value="item.value"
                v-for="item in options2"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="6">
            <el-select
              placeholder="请选择订单生产状态"
              v-model="struts"
              clearable
            >
              <el-option
                :key="item.value"
                :label="item.label"
                :value="item.value"
                v-for="item in options"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="6" v-if="whetherFullPayment === ''">
            <el-select
              placeholder="请选择订单支付状态"
              v-model="payStruts"
              clearable
            >
              <el-option
                :key="item.value"
                :label="item.label"
                :value="item.value"
                v-for="item in options1"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" @click="query">查询</el-button>
            <el-button @click="refreshs">刷新</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="tab">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column
            prop="orderCounts"
            label="订单总数"
            width="199"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            label="总金额"
            width="200"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.orderSumMoney | filterMoney }}
            </template>
          </el-table-column>
          <el-table-column
            prop="orderProcessingFee"
            label="总加工费"
            width="180"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            prop="orderPaperFee"
            label="总纸张费"
            align="center"
            width="200"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            prop="orderLogisticsCosts"
            label="总物流费"
            width="200"
            align="center"
            header-align="center"
          >
          </el-table-column>
        </el-table>
      </div>
      <div class="tablist">
        <el-table :data="tableData1" border style="width: 100%">
          <el-table-column
            prop="orderTime"
            label="下单时间"
            width="120"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            prop="orderId"
            label="订单编号"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            prop="orderName"
            label="产品名称"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            prop="userName"
            label="客户名称"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            label="订单金额"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.disCountFee | filterMoney }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="付款方式"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.paymentProportion === "1"
                  ? "首付30%"
                  : scope.row.paymentProportion === "2"
                  ? "全款"
                  : scope.row.paymentProportion === "3"
                  ? "线下付款"
                  : ""
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="postType"
            label="送货方式"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            label="产品类型"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.productId == 1038
                  ? "综合"
                  : scope.row.productId == 1036
                  ? "挂历"
                  : scope.row.productId == 1035
                  ? "台历"
                  : scope.row.productId == 1034
                  ? "精装书"
                  : scope.row.productId == 1032
                  ? "封套"
                  : scope.row.productId == 1028
                  ? "单页/海报"
                  : scope.row.productId == 1027
                  ? "教辅"
                  : scope.row.productId == 1024
                  ? "瓦楞彩箱"
                  : scope.row.productId == 1023
                  ? "单层卡纸彩盒"
                  : scope.row.productId == 1020
                  ? "画册/样本"
                  : scope.row.productId == 1018
                  ? "装帧加工"
                  : scope.row.productId == 1016
                  ? "印刷加工"
                  : scope.row.productId == 1015
                  ? "手提袋"
                  : ""
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="生产状态"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{ typeFunc(scope.row.status) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="quantitydemanded"
            label="产品数量"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            label="操作"
            width="110"
            align="center"
            header-align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="orderInfo(scope.row)"
                >订单详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!--订单详情-->
    <el-dialog :visible.sync="detail" class="detail" :title="title" width="55%">
      <order-details v-bind:orderId="orderId" :key="timer"></order-details>
    </el-dialog>
    <!-- 选择客户 -->
    <div class="dialogs">
      <el-dialog
        :close-on-click-modal="false"
        title="选择客户"
        :visible.sync="dialogVisible3"
        @close="closed"
      >
        <div class="queryMember">
          <el-row :gutter="10">
            <el-col :span="8">
              <el-input
                v-model.trim="custName"
                placeholder="请输入用户名称"
              ></el-input
            ></el-col>
            <el-col :span="5">
              <el-button type="primary" @click="queryMember">查询</el-button>
              <el-button @click="refMember">刷新</el-button>
            </el-col>
          </el-row>
        </div>
        <el-table
          :data="allList"
          border
          style="width: 100%"
          class="memberTable"
        >
          <el-table-column header-align="center" align="center" width="100">
            <template scope="scope">
              <el-radio
                :label="scope.row.user_ID"
                v-model="radio"
                @change.native="getCurrentRow(scope.row)"
              ></el-radio>
            </template>
          </el-table-column>

          <el-table-column
            property="name"
            label="用户名称"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="联系方式"
            width="180"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.phone ? scope.row.phone : scope.row.username
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            property="createTime"
            label="注册时间"
            width="200"
            header-align="center"
            align="center"
          >
          </el-table-column>
        </el-table>

        <div class="page">
          <el-pagination
            @size-change="handleSizeChange4"
            @current-change="handleCurrentChange4"
            :current-page="pageNumber3"
            :page-sizes="[5, 10, 20, 30]"
            :page-size="pageSize3"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total4"
          >
          </el-pagination>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="sureChange">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 分页处理 -->
    <div class="page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import api from "../../api/webAdmin/webAdmin";
import OrderDetails from "../OrderDetails/OrderDetails";
export default {
  name: "orderReport",
  components: {
    OrderDetails,
  },
  data() {
    return {
      radio: "", // 单选客户绑定的
      allList: [],
      custName: "", // 客户手机查询
      dialogVisible3: false,
      total4: 0, // 选择客户
      pageNumber3: 1,
      pageSize3: 5,
      oneMember: "",
      options1: [
        // 订单支付状态
        {
          value: "3",
          label: "待支付",
        },
        {
          value: "5",
          label: "已支付",
        },
      ],
      options2: [
        // 订单支付状态
        {
          value: "0",
          label: "全款",
        },
        {
          value: "1",
          label: "30%预付款",
        },
        {
          value: "2",
          label: "线下付款",
        },
      ],
      options: [
        // 订单生产状态

        {
          value: "1",
          label: "待审核",
        },
        {
          value: "2",
          label: "待排产",
        },
        {
          value: "3",
          label: "生产中",
        },
        {
          value: "4",
          label: this.userType === 0 ? "生产完成" : "待入库",
        },
        {
          value: "5",
          label: this.userType === 0 ? "待发货" : "已入库",
        },
        {
          value: "6",
          label: this.userType === 0 ? "已发货" : "待签收",
        },
        {
          value: "7",
          label: "订单取消",
        },
        {
          value: "8",
          label: "订单完成(已评价)",
        },
        {
          value: "9",
          label: "待印前制作",
        },
        {
          value: "10",
          label: "待客确认",
        },
        {
          value: "11",
          label: "已收货",
        },
      ],
      title: "",
      detail: false,
      total: 0,
      startTime: "",
      endTime: "",
      struts: "", //
      payStruts: "", // 3 未支付 5 已支付
      pageSize: 10,
      pageNumber: 1,
      whetherFullPayment: "", //  0:全款 1: 30% 预付款 2:线下
      tableData: [],
      tableData1: [],
      orderId: "",
      timer: "",
      user_ID: "",
      userType: "",
      memberId: "",
    };
  },
  created() {
    let obj = JSON.parse(window.sessionStorage.getItem("currentInfo"));
    if (obj) {
      this.user_ID = obj.user_ID;
      this.userType = obj.userType;
    }
    this.inits();
  },
  filters: {
    filterMoney(value) {
      const itemVal = Number(value).toFixed(3);
      if (itemVal === "NaN") {
        return "0.00";
      }
      const realVal = itemVal.substring(0, itemVal.length - 1);
      return realVal;
    },
  },
  watch: {
    allList(v) {
      if (v.length === 1) {
        let data = v[0];
        this.radio = data.user_ID;
        this.memberId = data.customerId;
        this.oneMember = data.name;
      }
    },
  },
  methods: {
    // 判断生产状态
    typeFunc(v) {
      let text = "";
      if (v === "-1") {
        return (text = "待核价");
      } else if (v === "1") {
        return (text = "待审核");
      } else if (v === "2") {
        return (text = "待排产");
      } else if (v === "3") {
        return (text = "生产中");
      } else if (v === "4") {
        if (this.userType === 0) {
          return (text = "生产完成");
        } else {
          return (text = "待入库");
        }
      } else if (v === "5") {
        if (this.userType === 0) {
          return (text = "待发货");
        } else {
          return (text = "已入库");
        }
      } else if (v === "6") {
        if (this.userType === 0) {
          return (text = "已发货");
        } else {
          return (text = "待签收");
        }
      } else if (v === "7") {
        return (text = "订单取消");
      } else if (v === "8") {
        return (text = "订单完成(已评价)");
      } else if (v === "9") {
        return (text = "待印前制作");
      } else if (v === "10") {
        return (text = "待客确认");
      } else if (v === "11") {
        return (text = "已收货");
      } else {
        return text;
      }
    },
    getCurrentRow(item) {
      console.log(item);
      this.memberId = item.customerId;
      this.oneMember = item.name;
    },
    // 取消
    cancel() {
      this.dialogVisible3 = false;
      this.radio = this.memberId = this.oneMember = "";
    },
    // 弹窗关闭
    closed() {
      if (!this.dialogVisible3) {
        // this.dialogVisible3 = false;
        if (this.radio === "") {
          this.oneMember = "";
          this.memberId = "";
          this.inits();
        }
      }
    },
    // 刷新客户
    refMember() {
      this.custName = "";
      this.pageNumber3 = 1;
      this.pageSize3 = 5;
      this.getUser();
    },
    // 查询客户
    queryMember() {
      this.getUser();
    },
    // 确定选择客户
    sureChange() {
      if (this.radio === "") {
        this.$message.warning("请选择客户！");
        return;
      }
      this.dialogVisible3 = false;
      this.inits();
    },
    // 选择客户
    changeMember() {
      this.custName = "";
      this.dialogVisible3 = true;
      this.getUser();
    },
    // 获取用户信息
    getUser() {
      api
        .queryUser(this.pageNumber3, this.pageSize3, "", "", this.custName, "0")
        .then((res) => {
          console.log("获取客户", res);
          if (res.success) {
            // this.allList = res.data.userPageInfo.list;
            this.allList = res.data.list;
            this.total4 = res.data.total;
          }
        });
    },
    // 订单详情
    orderInfo(item) {
      this.timer = new Date().getTime();
      this.title = "订单【" + item.orderId + "】详情";
      this.orderId = item.orderId;
      this.detail = true;
    },
    // 查询
    query() {
      // if (this.startTime === "" || this.endTime === "") {
      //   this.$message.warning("请输入查询区间");
      //   return;
      // }
      this.inits();
    },
    // 刷新
    refreshs() {
      this.startTime = this.endTime = this.struts = this.payStruts = this.whetherFullPayment = this.radio = this.oneMember = this.memberId = this.custName =
        "";
      this.inits();
    },
    // 获取报表数据
    inits() {
      api
        .report(
          this.startTime,
          this.endTime,
          this.struts,
          this.payStruts,
          this.pageSize,
          this.pageNumber,
          this.whetherFullPayment,
          this.memberId
        )
        .then((res) => {
          console.log("获取报表数据", res);
          if (res.success) {
            this.tableData = res.data.list;
            let data = res.data.orderInfoReportLists.list;
            if (data.length > 0) {
              data.forEach((el) => {
                el.orderTime = el.orderTime.substring(0, 10);
              });
            }
            this.tableData1 = data;
            this.total = res.data.orderInfoReportLists.total;
          } else {
            this.$message.err("获取报表失败");
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.inits();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.inits();
    },
    handleSizeChange4(val) {
      this.pageSize3 = val;
      this.getUser();
    },
    handleCurrentChange4(val) {
      this.pageNumber3 = val;
      this.getUser();
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 100%;
  .card-top {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #e6e6e6;
    padding: 0 30px;
    line-height: 50px;
    span {
      display: inline-block;
      width: 5px;
      height: 17px;
      background: #058af2;
      margin-right: 20px;
    }
    h3 {
      color: #058af2;
    }
  }
  .query {
    margin-top: 10px;
    .colls {
      display: flex;
      line-height: 40px;
      margin: 15px 0 15px 20px;
      span {
        margin-right: 5px;
      }
      .kuoz {
        margin: 0 10px;
      }
    }
    .coll {
      display: flex;
      margin-top: 15px;
      .span {
        width: 95px;
        line-height: 40px;
      }
    }
    .rows {
      margin-left: 45px;
    }
  }
  .tab {
    margin: 10px 0 20px 0;
    ::v-deep .el-table--enable-row-transition .el-table__body td {
      color: #ff0000;
      font-weight: 600;
    }
  }
  .tablist {
    margin: 10px 0 20px 0;
    ::v-deep .el-table td,
    .el-table th {
      padding: 5px 0;
    }
  }
}
.page {
  margin: 10px 0;
}
.dialogs {
  .queryMember {
    margin: 10px 0;
  }
  ::v-deep .el-radio__label {
    display: none;
  }
}
</style>
