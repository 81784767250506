<template>
  <div class="payContent detail">
    <div class="printCont" id="printArea">
      <div class="hand">
        <h2>{{orderInfo.level}}  {{orderInfo.productname}} 印刷订单(ORDER)</h2>
        <div class="hand_line">
          <div class="title">产品名称</div>
          <span>:</span>
          <div class="text">{{ orderInfo.ordername }} </div>
        </div>
        <div class="hand_line">
          <div class="title">订单编号</div>
          <span>:</span>
          <div class="text">{{ orderInfo.id }}</div>
        </div>
        <div class="hand_line">
          <div class="title">企业名称</div>
          <span>:</span>
          <div class="text">{{ orderInfo.customername }}</div>
        </div>
        <div class="hand_line">
          <div class="title">会员级别</div>
          <span>:</span>
          <div class="text">{{ custLevelName }}</div>
        </div>
        <div class="hand_line">
          <div class="title">所属客服</div>
          <span>:</span>
          <div class="text">{{ salesman }}</div>
        </div>
        <div class="hand_line">
          <div class="title">联系电话</div>
          <span>:</span>
          <div class="text">{{ orderInfo.contacttel }}</div>
        </div>
        <!-- <div class="hand_line">
                  <div class="title">会员编号</div>
                  <span>:</span>
                  <div class="text">{{ orderInfo.userid }}</div>
                </div> -->
        <!--            <div class="hand_line">-->
        <!--                <div class="title">邮箱</div>-->
        <!--                <span>:</span>-->
        <!--                <div class="text"></div>-->
        <!--            </div>-->
        <div class="hand_line">
          <div class="title">下单日期</div>
          <span>:</span>
          <div class="text">{{ orderInfo.ordertime }}</div>
        </div>
        <div class="hand_line">
          <div class="title">交货日期</div>
          <span>:</span>
          <div class="text">{{ orderInfo.completiontime }}</div>
        </div>
        <div
          class="hand_line"
          v-if="orderProduct.postType != '工厂自提'"
          style="width: 100%"
        >
          <div class="title">收货地址</div>
          <span>:</span>
          <div class="text">
             {{ outOrder.resiveAddr == "unll" ? "" : outOrder.resiveAddr }}
          </div>
        </div>
        <h4>
          下单人承诺：所提供资料（含知识产权）真实、合法，由此引发的所有问题由下单人负责。此订单等同于印刷委托书
        </h4>
      </div>

      <!--手拎袋-->
      <div v-if="productId == 1015 ||productId == 2015">
        <table>
          <tr>
            <th>成品尺寸(高*宽*厚)</th>
            <th>印刷数量</th>
            <th>印刷颜色</th>
            <th>印刷纸张</th>
          </tr>
          <tr>
            <td>{{ info.productSpec }}</td>
            <td>{{ info.productNum }}</td>
            <td>{{ info.printColor }}</td>
            <td>{{ info.paperName }}</td>
          </tr>
          <tr class="packType">
            <th>后道工艺</th>
            <td colspan="4">{{ info.aftDesc }}</td>
          </tr>
          <!--                    <tr class="packType">-->
          <!--                        <th>包装方式</th>-->
          <!--                        <td colspan="4">{{ info.packageType }}</td>-->
          <!--                    </tr>-->
          <!--                    <tr class="packType">-->
          <!--                        <th>印刷方式</th>-->
          <!--                        <td colspan="4">{{ orderProduct.printType }}</td>-->
          <!--                    </tr>-->
          <!--                    <tr class="packType">-->
          <!--                        <th>送货方式</th>-->
          <!--                        <td colspan="4">{{ orderProduct.postType }}</td>-->
          <!--                    </tr>-->
        </table>
        <table>
          <tr>
           <th v-if="orderInfo.needDesign ==='1'">设计要求</th>
            <th>包装方式</th>
            <th>印刷方式</th>
            <th>送货方式</th>
          </tr>
          <tr>
            <td v-if="orderInfo.needDesign ==='1'">设计{{ orderInfo.pnums }}p</td>
            <td>{{ info.packageType }}</td>
            <td>{{ orderProduct.printType }}</td>
            <td>{{ orderProduct.postType }}</td>
          </tr>
        </table>
      </div>

      <!--普通画册-->
      <div v-if="productId == 1020 || productId == 2020">
        <table>
          <tr>
            <th>成品尺寸(宽*高)</th>
            <th>印刷数量</th>
            <th>装订方式</th>
          </tr>
          <tr>
            <td>{{ info.productSpec }}</td>
            <td>{{ info.productNum }}</td>
            <td>{{ info.bindingType }}</td>
          </tr>
        </table>
        <table>
          <tr>
            <th>部件名称</th>
            <th>P数/尺寸</th>
            <th>印刷颜色</th>
            <th>印刷纸张</th>
            <th>后道工艺</th>
          </tr>
          <tr v-for="(item, index) in info.partsList" :key="index">
            <td style="font-weight: bold">{{ item.partsName }}</td>
            <td>{{ item.pNum }}</td>
            <td>{{ item.printColor }}</td>
            <td>{{ item.paperName }}</td>
            <td>{{ item.aftDesc }}</td>
          </tr>
          <!--                    <tr class="packType">-->
          <!--                        <td style="font-weight: bold">包装方式</td>-->
          <!--                        <td colspan="4">{{ info.packageType }}</td>-->
          <!--                    </tr>-->
          <!--                    <tr class="packType">-->
          <!--                        <td style="font-weight: bold">印刷方式</td>-->
          <!--                        <td colspan="4">{{ orderProduct.printType }}</td>-->
          <!--                    </tr>-->
          <!--                    <tr class="packType">-->
          <!--                        <td style="font-weight: bold">送货方式</td>-->
          <!--                        <td colspan="4">{{ orderProduct.postType }}</td>-->
          <!--                    </tr>-->
        </table>
        <table>
          <tr>
            <th v-if="orderInfo.needDesign ==='1'">设计要求</th>
            <th>包装方式</th>
            <th>印刷方式</th>
            <th>送货方式</th>
          </tr>
          <tr>
            <td v-if="orderInfo.needDesign ==='1'">设计{{ orderInfo.pnums }}p</td>
            <td>{{ info.packageType }}</td>
            <td>{{ orderProduct.printType }}</td>
            <td>{{ orderProduct.postType }}</td>
          </tr>
        </table>
      </div>

      <!--单页-->
      <div v-if="productId == 1028 || productId == 2028">
        <table>
          <tr>
            <th>成品尺寸(宽*高)</th>
            <th>印刷数量</th>
            <th>印刷颜色</th>
            <th>印刷纸张</th>
            <th>后道工艺</th>
          </tr>
          <tr>
            <td>{{ info.productSpec }}</td>
            <td>{{ info.productNum }}</td>
            <td>{{ info.printColor }}</td>
            <td>{{ info.paperName }}</td>
            <td>{{ info.aftDesc }}</td>
          </tr>
          <!--                    <tr class="packType">-->
          <!--                        <th>包装方式</th>-->
          <!--                        <td colspan="4">{{ info.packageType }}</td>-->
          <!--                    </tr>-->
          <!--                    <tr class="packType">-->
          <!--                        <th>印刷方式</th>-->
          <!--                        <td colspan="4">{{ orderProduct.printType }}</td>-->
          <!--                    </tr>-->
          <!--                    <tr class="packType">-->
          <!--                        <th>送货方式</th>-->
          <!--                        <td colspan="4">{{ orderProduct.postType }}</td>-->
          <!--                    </tr>-->
        </table>
        <table>
          <tr>
            <th v-if="orderInfo.needDesign ==='1'">设计要求</th>
            <th>包装方式</th>
            <th>印刷方式</th>
            <th>送货方式</th>
          </tr>
          <tr>
            <td v-if="orderInfo.needDesign ==='1'">设计{{ orderInfo.pnums }}p</td>
            <td>{{ info.packageType }}</td>
            <td>{{ orderProduct.printType }}</td>
            <td>{{ orderProduct.postType }}</td>
          </tr>
        </table>
      </div>

      <!--封套-->
      <div v-if="productId == 1032 || productId == 2032 ">
        <table>
          <tr>
            <th>成品尺寸(高*宽*兜底)</th>
            <th>印刷数量</th>
            <th>印刷颜色</th>
            <th>印刷纸张</th>
            <th>后道工艺</th>
          </tr>
          <tr>
            <td>{{ orderProduct.productSpec }}</td>
            <td>{{ info.productNum }}</td>
            <td>{{ info.printColor }}</td>
            <td>{{ info.paperName }}</td>
            <td>{{ info.aftDesc }}</td>
          </tr>
          <!--                    <tr class="packType">-->
          <!--                        <th>包装方式</th>-->
          <!--                        <td colspan="4">{{ info.packageType }}</td>-->
          <!--                    </tr>-->
          <!--                    <tr class="packType">-->
          <!--                        <th>印刷方式</th>-->
          <!--                        <td colspan="4">{{ orderProduct.printType }}</td>-->
          <!--                    </tr>-->
          <!--                    <tr class="packType">-->
          <!--                        <th>送货方式</th>-->
          <!--                        <td colspan="4">{{ orderProduct.postType }}</td>-->
          <!--                    </tr>-->
        </table>
        <table>
          <tr>
            <th v-if="orderInfo.needDesign ==='1'">设计要求</th>
            <th>包装方式</th>
            <th>印刷方式</th>
            <th>送货方式</th>
          </tr>
          <tr>
            <td v-if="orderInfo.needDesign ==='1'">设计{{ orderInfo.pnums }}p</td>
            <td>{{ info.packageType }}</td>
            <td>{{ orderProduct.printType }}</td>
            <td>{{ orderProduct.postType }}</td>
          </tr>
        </table>
      </div>

      <!--精装书-->
      <div v-if="productId == 1034 || productId == 2034">
        <table>
          <tr>
            <th>成品尺寸(高*宽)</th>
            <th>印刷数量</th>
            <th>装订方式</th>
          </tr>
          <tr>
            <td>{{ info.productSpec }}</td>
            <td>{{ info.productNum }}</td>
            <td>{{ orderProduct.bindingType }} {{ orderProduct.numDesc }}</td>
          </tr>
          <!--          <tr class="packType">-->
          <!--            <th>包装方式</th>-->
          <!--            <td colspan="2">{{ orderProduct.packType }}</td>-->
          <!--          </tr>-->
        </table>
        <table>
          <tr>
            <th>部件名称</th>
            <th>部件数量</th>
            <th>印刷颜色</th>
            <th>印刷纸张</th>
            <th>后道工艺</th>
          </tr>
          <tr v-for="(item, index) in info.partsList" :key="index">
            <td style="font-weight: bold">{{ item.partsName }}</td>
            <td>{{ item.pNum }}</td>
            <td>{{ item.printColor }}</td>
            <td>{{ item.paperName }}</td>
            <td>{{ item.aftDesc }}</td>
          </tr>
          <!--                    <tr class="packType">-->
          <!--                        <td style="font-weight: bold">包装方式</td>-->
          <!--                        <td colspan="4">{{ info.packageType }}</td>-->
          <!--                    </tr>-->
          <!--                    <tr class="packType">-->
          <!--                        <td style="font-weight: bold">印刷方式</td>-->
          <!--                        <td colspan="4">{{ orderProduct.printType }}</td>-->
          <!--                    </tr>-->
          <!--                    <tr class="packType">-->
          <!--                        <td style="font-weight: bold">送货方式</td>-->
          <!--                        <td colspan="4">{{ orderProduct.postType }}</td>-->
          <!--                    </tr>-->
        </table>
        <table>
          <tr>
            <th v-if="orderInfo.needDesign ==='1'">设计要求</th>
            <th>包装方式</th>
            <th>印刷方式</th>
            <th>送货方式</th>
          </tr>
          <tr>
            <td v-if="orderInfo.needDesign ==='1'">设计{{ orderInfo.pnums }}p</td>
            <td>{{ info.packageType }}</td>
            <td>{{ orderProduct.printType }}</td>
            <td>{{ orderProduct.postType }}</td>
          </tr>
        </table>
      </div>

      <!--台历-->
      <div v-if="productId == 1035  || productId == 2035">
        <table>
          <tr>
            <th>成品尺寸(x*y*z示例图)</th>
            <th>印刷数量</th>
            <th>装订方式</th>
          </tr>
          <tr>
            <td>{{ info.productSpec }}</td>
            <td>{{ info.productNum }}</td>
            <td>{{ info.bindingType }}</td>
          </tr>
          <!--                    <tr class="packType">-->
          <!--                        <th>包装方式</th>-->
          <!--                        <td colspan="3">{{ info.packageType }}</td>-->
          <!--                    </tr>-->
          <!--                    <tr class="packType">-->
          <!--                        <th>印刷方式</th>-->
          <!--                        <td colspan="4">{{ orderProduct.printType }}</td>-->
          <!--                    </tr>-->
          <!--                    <tr class="packType">-->
          <!--                        <th>送货方式</th>-->
          <!--                        <td colspan="4">{{ orderProduct.postType }}</td>-->
          <!--                    </tr>-->
        </table>
        <table>
          <tr>
            <th v-if="orderInfo.needDesign ==='1'">设计要求</th>
            <th>包装方式</th>
            <th>印刷方式</th>
            <th>送货方式</th>
          </tr>
          <tr>
            <td v-if="orderInfo.needDesign ==='1'">设计{{ orderInfo.pnums }}p</td>
            <td>{{ info.packageType }}</td>
            <td>{{ orderProduct.printType }}</td>
            <td>{{ orderProduct.postType }}</td>
          </tr>
        </table>
        <table>
          <tr>
            <th>部件名称</th>
            <th>部件数量</th>
            <th>印刷颜色</th>
            <th>印刷纸张</th>
            <th>后道工艺</th>
          </tr>
          <tr v-for="(item, index) in info.partsList" :key="index">
            <td style="font-weight: bold">{{ item.partsName }}</td>
            <td>{{ item.pNum }}</td>
            <td>{{ item.printColor }}</td>
            <td>{{ item.paperName }}</td>
            <td>{{ item.aftDesc }}</td>
          </tr>
          <tr class="packType">
            <td style="font-weight: bold">成品工艺</td>
            <td colspan="4">{{ info.processName }}</td>
          </tr>
          <tr class="packType">
            <td style="font-weight: bold">包装方式</td>
            <td colspan="4">{{ info.packageType }}</td>
          </tr>
        </table>
      </div>

      <!--挂历-->
      <div v-if="productId == 1036  || productId == 2026">
        <table>
          <tr>
            <th>成品尺寸(高*宽)</th>
            <th>印刷数量</th>
            <th>装订方式</th>
          </tr>
          <tr>
            <td>{{ info.productSpec }}</td>
            <td>{{ info.productNum }}</td>
            <td>{{ info.bindingType }}</td>
          </tr>
        </table>
        <table>
          <tr>
            <th>部件名称</th>
            <th>部件数量</th>
            <th>印刷颜色</th>
            <th>印刷纸张</th>
            <th>后道工艺</th>
          </tr>
          <tr v-for="(item, index) in info.partsList" :key="index">
            <td style="font-weight: bold">{{ item.partsName }}</td>
            <td>{{ item.pNum }}</td>
            <td>{{ item.printColor }}</td>
            <td>{{ item.paperName }}</td>
            <td>{{ item.aftDesc }}</td>
          </tr>
          <!--                    <tr class="packType">-->
          <!--                        <td style="font-weight: bold">包装方式</td>-->
          <!--                        <td colspan="4">{{ info.packageType }}</td>-->
          <!--                    </tr>-->
          <!--                    <tr class="packType">-->
          <!--                        <td style="font-weight: bold">印刷方式</td>-->
          <!--                        <td colspan="4">{{ orderProduct.printType }}</td>-->
          <!--                    </tr>-->
          <!--                    <tr class="packType">-->
          <!--                        <td style="font-weight: bold">送货方式</td>-->
          <!--                        <td colspan="4">{{ orderProduct.postType }}</td>-->
          <!--                    </tr>-->
        </table>
        <table>
          <tr>
            <th v-if="orderInfo.needDesign ==='1'">设计要求</th>
            <th>包装方式</th>
            <th>印刷方式</th>
            <th>送货方式</th>
          </tr>
          <tr>
            <td v-if="orderInfo.needDesign ==='1'">设计{{ orderInfo.pnums }}p</td>
            <td>{{ info.packageType }}</td>
            <td>{{ orderProduct.printType }}</td>
            <td>{{ orderProduct.postType }}</td>
          </tr>
        </table>
      </div>

      <table class="cost">
        <tr>
          <th>系统报价</th>
          <td class="orange">￥{{ orderProduct.sysFee }}</td>

          <th v-if="this.orderInfo.discountfee == this.orderProduct.productFee">
            成交金额
          </th>
          <th v-else>订单费用</th>
          <td class="orange">￥{{ orderProduct.productFee }}</td>
        </tr>
        <tr v-if="orderInfo.invoice && orderInfo.invoice !== '0'">
          <th>税费</th>
          <td class="orange">￥{{ moneyInfo.税费
            }}</td>
          <th></th>
          <td class="orange"> </td>
        </tr>
        <tr v-if="userType != 0">
          <th>纸张费</th>
          <td class="orange">￥{{ moneyInfo.纸张成本 }}</td>
          <!--加工费：【系统报价】-【纸张成本】-【运费】-->
          <th>加工费</th>
          <td class="orange">
            ￥{{
              (orderProduct.sysFee - moneyInfo.纸张成本 - moneyInfo.物流费用)
                | numbers
            }}
          </td>
        </tr>
        <tr v-if="userType != 0">
          <th>运费</th>
          <td class="orange">￥{{ moneyInfo.物流费用 }}</td>

          <!--纸张占比：【纸张成本】÷【系统报价】-->
          <th>纸张占比</th>
          <td class="orange">{{ percentageOfPaper }}%</td>
        </tr>
        <tr v-if="this.orderInfo.discountfee != this.orderProduct.productFee">
          <th>成交金额</th>
          <td class="orange">￥{{ orderInfo.discountfee }}</td>
          <th>已支付</th>
          <td class="orange">￥{{ payMoney }}</td>
        </tr>
        <tr>
          <th v-if="this.orderInfo.discountfee == this.orderProduct.productFee">
            已支付
          </th>
          <td
            v-if="this.orderInfo.discountfee == this.orderProduct.productFee"
            class="orange"
          >
            ￥{{ payMoney }}
          </td>
          <!--                </tr>-->
          <!--                <tr>-->
          <th>账户余额</th>
          <td
            v-if="this.orderInfo.discountfee != this.orderProduct.productFee"
            colspan="3"
            class="orange"
          >
            ￥{{ userLastMoney }}
          </td>
          <td v-else class="orange">￥{{ userLastMoney }}</td>
        </tr>

        <tr>
          <th>订单备注</th>
          <td colspan="3">{{ orderInfo.memo }}</td>
        </tr>
        <tr>
          <th>下单人</th>
          <td>{{ NextPerson }}</td>
          <!--                </tr>-->
          <!--                <tr v-if="userType === 0 || !showBtn">-->
          <th v-if="userType === 0 || !showBtn">审核</th>
          <td v-if="userType === 0 || !showBtn">
            {{ orderInfo.auditopinion }}
          </td>
        </tr>

        <tr>
          <th>发票要求</th>
          <td colspan="3">{{orderInfo.invoiceName }}</td>
        </tr>

      </table>
    </div>
    <el-button type="primary" class="print" v-print="'#printArea'"
      >打印
    </el-button>
    <div class="bottom" v-if="userType === 1 && showBtn">
      <el-button type="primary" :disabled="disabled" @click="applyInvoice">
        {{ status !== "1" ? "审核已通过" : "审核" }}
      </el-button>
      <el-button @click="cancels">关闭</el-button>
      <p style="color: #ff0000; font-weight: 600">当订单完成支付方能审核</p>
    </div>
    <el-dialog
      title="审核订单"
      :visible.sync="dialogVisible"
      width="30%"
      append-to-body
    >
      <div class="topIpt" v-if="radio != '1'">
        <span>审核意见：</span>
        <el-input
          type="textarea"
          autosize
          placeholder="请输入审核内容"
          v-model="textarea"
          class="ipt"
        >
        </el-input>
      </div>
      <!--        <div class="topIpt">-->
      <!--          <span>审核人：</span>-->
      <!--          <el-input-->
      <!--            class="ipt"-->
      <!--            v-model="input"-->
      <!--            placeholder="当前审核人"-->
      <!--          ></el-input>-->
      <!--        </div>-->
      <div class="bottom">
        <!--          <el-switch-->
        <!--            style="display: block"-->
        <!--            v-model="value2"-->
        <!--            active-color="#13ce66"-->
        <!--            inactive-color="#ff4949"-->
        <!--            active-value="0"-->
        <!--            inactive-value="7"-->
        <!--          >-->
        <!--          </el-switch>-->
        <!--          <span>{{ value2 === "0" ? "审核通过" : "审核不通过" }}</span>-->

        <el-radio-group v-model="radio">
          <el-radio label="1">审核通过</el-radio>
          <el-radio label="2">审核驳回</el-radio>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import picUrl from "../../api/picture";
import api from "../../api/webAdmin/webAdmin";

export default {
  name: "OrderDetails",
  props: ["orderId", "ordersInfo"],
  data() {
    return {
      salesman: "", // 所属客服
      custLevelName: "", // 会员级别
      disabled: false,
      showPrint: true,
      status: "1",
      textarea: "",
      radio: "1",
      dialogVisible: false,
      nowdate: "", //打印日期
      receiveAdderss: "", //用户ID
      custData: "", //
      orderInfo: "", //订单信息
      outOrder: "",
      orderProduct: "", //订单详情
      info: "", //订单详情
      productId: "", //产品类型
      moneyInfo: "", //费用列表
      percentageOfPaper: "", //纸张占比
      userLastMoney: "", //账户余额
      payMoney: "", //已支付
      NextPerson: "", //下单人
      picUrls: "",
      userType: 0,
      userId: "",
      showBtn: false,
      uploadEntry: 0, // 0:未上传文件 ，>0 :已上传
      payStruts: 3, // 3：待支付，4：已支付（预付款），5：已支付

    };
  },
  filters: {
    numbers(value) {
      let reverValue = 0;
      if (!isNaN(value) && value !== "") {
        // 截取当前数据到小数点后两位
        reverValue = parseFloat(value).toFixed(2);
      } else {
        reverValue = 0;
      }
      return reverValue;
    },
  },
  created() {
    console.log("订单详情", this.ordersInfo);
    let obj = JSON.parse(window.sessionStorage.getItem("currentInfo"));
    if (obj) {
      this.userType = obj.userType;
      this.userId = obj.user_ID;
    }
    // 订单管理界面（审核使用）
    if (this.ordersInfo) {
      console.log("执行了这里");
      this.status = this.ordersInfo.status;
      this.payStruts = this.ordersInfo.payStruts;
      this.showBtn = true;
      this.showPrint = false;
      this.uploadEntry = this.ordersInfo.uploadEntry;
      // if (this.ordersInfo.whetherFullPayment === 2) {
      // 线下支付订单
      // if (this.payStruts === 3 && this.uploadEntry <= 0) {
      //   this.disabled = true;
      // } else if (this.payStruts === 3 && this.uploadEntry > 0) {
      //   this.disabled = false;
      // }
      // } else {
      if (this.status !== "1") {
        this.disabled = true;
        // return;
      } else {
        if (this.payStruts === 3) {
          this.disabled = true;
        } else if (this.payStruts === 5) {
          this.disabled = false;
        }
      }
      // }
    }
    this.picUrls = picUrl.picUrl;


    let year = new Date().getFullYear(); //得到年份
    let month = new Date().getMonth(); //得到月份
    let date = new Date().getDate(); //得到日期
    let hour = new Date().getHours(); //得到小时
    let minu = new Date().getMinutes(); //得到分钟
    let miao = new Date().getSeconds(); //秒
    month = month + 1;
    if (month < 10) month = "0" + month;
    if (date < 10) date = "0" + date;
    if (hour < 10) hour = "0" + hour;
    if (minu < 10) minu = "0" + minu;
    if (miao < 10) miao = "0" + miao;
    //打印日期
    this.nowdate =
      year + "-" + month + "-" + date + " " + hour + ":" + minu + ":" + miao;
    console.log(this.nowdate);

    console.log(this.orderId);
    this.$http
      .get("/orderinfo/getProductInfoByOrderId?orderId=" + this.orderId)
      .then((res) => {
        console.log("获取订单详情", res);
        if (res.data.code == 200) {
          // this.$message({
          //     message: "加载成功",
          //     type: "success",
          // });
          this.salesman = res.data.data.custData.salesman;
          // 会员级别
          this.custLevelName = res.data.data.custData.custLevelName;
          console.log("会员级别", this.custLevelName);
          this.custData =
            res.data.data.custData == null ? {} : res.data.data.custData;
          //费用列表
          this.moneyInfo = res.data.data.moneyInfo;
          //订单信息
          this.orderInfo = res.data.data.orderInfo;
          this.outOrder = res.data.data.outOrder;
          //表格
          this.info = res.data.data.orderProduct.info;
          //订单详情
          this.orderProduct = res.data.data.orderProduct;
          //会员ID（用户id）
          this.receiveAdderss = res.data.data.receiveAdderss;
          //产品ID
          this.productId = res.data.data.orderProduct.productId;
          //纸张占比
          this.percentageOfPaper = res.data.data.percentageOfPaper;
          //已支付
          this.payMoney =
            res.data.data.payMoney == null ? 0 : res.data.data.payMoney;
          //下单人
          this.NextPerson = res.data.data.user.name;

          this.info = JSON.parse(res.data.data.orderProduct.info);
          console.log(JSON.parse(res.data.data.orderProduct.info));

          //账户余额
          if (res.data.data.useWallet == undefined) {
            this.userLastMoney = res.data.data.balance
              ? res.data.data.balance
              : 0;
          } else {
            this.userLastMoney = res.data.data.useWallet.userLastMoney
              ? res.data.data.useWallet.userLastMoney
              : 0;
          }
          console.log(this.userLastMoney);
        } else {
          // this.$message.error('报价页面加载失败');
          console.log(res.data.message);
        }
      })
      .catch((error) => {
        console.error("Error", error);
      });
  },
  mounted() {},
  methods: {
    // 返回会员级别
    // returnLevel() {
    //   this.$nextTick(() => {
    //     console.log("会员级别", this.custLevelName);
    //     return this.custLevelName;
    //   });
    // },
    // 关闭.

    cancels() {
      console.log("点击了关闭");
      this.$emit("changesDigs");
    },
    applyInvoice() {
      this.dialogVisible = true;
    },
    // 取消
    cancel() {
      this.textarea = "";
      this.input = "";
      this.dialogVisible = false;
    },
    // 确定
    sure() {
      if (this.radio == "2") {
        if (this.textarea === "") {
          this.$message.warning("请填写审核意见");
          return;
        }
      }
      // if (this.input === "") {
      //   this.$message.warning("请填写审核人");
      //   return;
      // }
      let data = {
        auditopinion: this.radio == "2" ? this.textarea : "审核通过",
        auditopinionaccout: this.ordersInfo.id,
        // stauts: this.value2,
        status: this.radio == "1" ? 9 : 7,
      };
      api
        .orderExamine(this.orderId, data)
        .then((res) => {
          console.log("审核", res);
          if (res.success) {
            this.$message.success("审核完成");
            this.cancel();
            this.cancels();
          } else {
            this.$message.error("审核失败");
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
  @page {
    size: auto;
    margin: 3mm;
  }
}

/*订单详情*/
.printCont {
  .detail_top {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    img {
      width: 30px;
      height: 30px;
      border: 1px solid #f7f7f7;
    }

    p {
      text-align: left;
    }
  }

  .hand {
    h2 {
      margin: 15px 0;
      text-align: center;
    }

    .hand_line {
      display: inline-flex;
      width: 50%;
      height: 30px;
      line-height: 30px;

      .title,
      span {
        font-weight: bold;
        color: black;
      }

      .title {
        width: 80px;
        text-align: justify;
        text-align-last: justify;
      }

      .text {
        border-bottom: 1px solid #eee;
        margin-left: 20px;
        color: #666;
        width: calc(100% - 80px);
        text-align: left;
        line-height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    h4 {
      color: red;
      text-align: center;
      margin: 10px 0;
    }
  }

  /*表格*/
  table {
    border: 1px solid #ebeef5;
    width: 100%;
    /*tr{*/
    /*    border-bottom: 1px solid #EBEEF5;*/
    /*}*/
    /*tr:last-of-type{*/
    /*    border-bottom: 0;*/
    /*}*/
    tr {
      th {
        color: #909399;
        font-size: 14px;
        font-weight: bold;
        padding: 12px 10px;
        text-align: center;
        border-right: 1px solid #ebeef5;
        border-bottom: 1px solid #ebeef5;
      }

      th:last-of-type {
        border-right: 0;
      }

      td {
        color: #606266;
        font-size: 14px;
        padding: 12px 10px;
        text-align: center;
        border-right: 1px solid #ebeef5;
        border-bottom: 1px solid #ebeef5;
      }

      td:last-of-type {
        border-right: 0;
      }

      .orange {
        color: #eb6d00;
        font-size: 20px;
      }
    }

    tr:last-of-type th {
      border-bottom: 0;
    }

    tr:last-of-type td {
      border-bottom: 0;
    }

    .packType th {
      border-right: 1px solid #ebeef5 !important;
    }

    .packType td {
      border-right: 1px solid #ebeef5 !important;
    }

    .packType td:last-of-type {
      border-right: 0 !important;
    }
  }

  .cost {
    margin-top: 15px;

    tr {
      th {
        border-right: 1px solid #ebeef5 !important;
        width: 25%;
      }
    }
  }
}

.print {
  margin: 10px auto;
  display: flex;
}

.bottom {
  text-align: center;
  margin-top: 15px;
}
</style>
